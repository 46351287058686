<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import axios from "axios";
    import Swal from "sweetalert2";
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Tambah Provinsi",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Tambah Provinsi",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Provinsi",
                        href: "/master/provinsi",
                    },
                    {
                        text: "Tambah Provinsi",
                        active: true,
                    },
                ],
                // Catch Error Axios
                axiosCatchError: null,
                // variable Page
                prov_nama: null,
                prov_kode: null,
            };
        },
        mounted() {},
        methods:{
            // Method dari masing masing fungsi di dalam file ini
            StoreData() {
                let self = this;
                Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});

                var config_store_data = {
                    method: "post",
                    url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/provinsi",
                    data: {
                        prov_kode: self.prov_kode,
                        prov_nama: self.prov_nama
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                };

                axios(config_store_data).then(function (response) {
                    console.log(response)
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman master provinsi segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-provinsi" });
                        }
                    });
                }).catch(function (error) {
                    self.axiosCatchError = error.response.data.data
                    Swal.close();
                });
            }
        }
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">

                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{axiosCatchError}}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0]}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Kode Provinsi" label-for="formrow-kode-provinsi-input">
                                        <b-form-input id="formrow-kode-provinsi-input" placeholder="Masukkan Kode Provinsi..." type="text" v-model="prov_kode"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Nama Provinsi" label-for="formrow-nama-provinsi-input">
                                        <b-form-input id="formrow-nama-provinsi-input" placeholder="Masukkan Nama Provinsi..." type="text" v-model="prov_nama"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
